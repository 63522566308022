.error-overlay {
  padding: 10px;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  background: #b3b3b3;
  color: #fff;

  &__message {
    text-align: center;
  }

  &__link {
    color: #fff;
  }
}

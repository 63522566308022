.file-input {
  &__input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 4px;
    border: 0;
    box-shadow: 0 0 6px 2px rgba(0,0,0,.2);
  }
}
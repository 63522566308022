.checkbox {
  $root: &;

  display: block;
  position: relative;
  padding-left: 2.1875rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  font-size: 0.9375rem;

  &__real-input {
    display: none;
  }

  &__checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 1px solid #ccc;

    &_checked {
      background-color: #129acc;
    }

    &::after {
      content: "";
      position: absolute;
      left: 6px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
  
  &_disabled {
    #{$root}__checkmark {
      background-color: #c1c1c1;
    }
    color: #c1c1c1;
  }
}

.button {

  & .MuiButtonBase-root {
    background-color: #129acc;
    text-transform: inherit;
    &:hover {
      background-color: #129acc;
    }
  }

  & .MuiButtonBase-root.Mui-disabled {
    color: #fff
  }

  &_size {
    &_large {
      padding: 0.625rem 0.9375rem;
    }
    &_dynamic {
      width: 100%;
      height: 100%;
    }
  }

}

.tree-menu {
  height: 100%;
  max-width: 100%;

  & > div {
    height: 87%;
    outline: none;
  }

  & .rstm-tree-item {
    border-bottom: none;
  }

  & .rstm-tree-item-level0 {
    padding-left: 0 !important;
  }

  & .rstm-tree-item-level1 {
    padding-left: 2.5rem !important;
  }

  & .tree-item-group {
    width: initial;
    padding: 0;
    overflow-y: auto;
    height: 100%;
  }

  & .tree-item {
    font-family: 'Helvetica', sans-serif;
  }
}

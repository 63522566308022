.modal {

  &__preloader {
    margin-right: 0.625rem;
  }

  &__action {
    padding-right: 0.625rem;
    height: 2.1875rem;

    &_cancel {
      margin-left: 0.625rem;
    }
  }

  & .MuiButton-label {
    text-transform: initial;
  }

  & .MuiDialogActions-root {
    padding: 0.5rem 0.9375rem 1rem;
  }

  & .MuiButton-root {
    background-color: #129acc;
    text-transform: capitalize;
    &:hover {
      background-color: #129acc;
    }
  }
  & .MuiDialogTitle-root {
    &#responsive-dialog-sub-title {
      padding-top: 0;
    }
  }
  & .MuiDialogContent-root {
    overflow-y: hidden;
  }
}

html {
  height: 100%;
  font-size: 16px;
  font-family: 'Open Sans', Tahoma, Sans-Serif, sans-serif;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Open sans",  "Roboto", "Helvetica", "Arial", sans-serif;
}

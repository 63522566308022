.handsontable TH,
.handsontable TD {
  min-width: 100px;
  max-width: 500px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.2;
}

.handsontable TD {
  word-wrap: break-word;
  padding-left: 7px;
}

.handsontable TD.mapped {
  background-color: #129accb5 !important;
  color: #fff !important;
}

.handsontable td.htInvalid.mapped {
  background: #ff4c42 !important;
  color:#fff;
}

.handsontable .fill {
  background-color: rgba(75, 137, 255, .1);
  opacity: .9 !important;
}

.handsontable .wtBorder.fill {
  background-color: rgb(75, 137, 255) !important;
}

.placement,
.hotCabinet.placement,
.required-data.placement.mapped,
.required-data.placement {
  background-color: green !important;
  color:#fff !important;
}

.htDropdownMenu:not(.htGhostTable) {
  z-index: 999999 !important;
}

.htFiltersConditionsMenu:not(.htGhostTable) {
  z-index: 1000000 !important;
}

.handsontable.listbox td, .handsontable.listbox th {
  max-width: 350px;
  white-space: initial;
  text-overflow: initial;
  word-break: break-all;
  line-height: 1;
  vertical-align: middle;
}

.ht_editor_visible {
  // decreased z-index for hide dropdown under fixed columns
  z-index: 119;
}

.handsontable {
  .autocorrected-cell {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 13px;
      height: 14px;
      transform: rotate(45deg);
      background-color: #059b1d;
      left: -7px;
      top: -7px;
    }
  }

  .date-editor-input {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
    visibility: hidden;
  }
}

.input {
  position: relative;

  &__input {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 4px;
    border: 0;
    box-shadow: 0 0 6px 2px rgba(0,0,0,.2);
  }

  &__clear {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 7px;
    top: calc(50% - 8px);
    background-image: url(./img/close.svg);
    cursor: pointer;
  }
}
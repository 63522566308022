.radio-list {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  &__title {
    margin-bottom: 0.625rem;
    font-size: 0.875rem;
    font-weight: 600;
  }

  & .MuiRadio-colorPrimary.Mui-checked {
    color: #129acc;
  }

  & .MuiFormControlLabel-label {
    font-size: 0.875rem;
  }

  &_inline {
    & .MuiFormGroup-root {
      flex-direction: row;
    }

    & .MuiFormControlLabel-root {
      margin-right: 3.125rem;
    }
  }
}
